/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*UDUCAT:GLP-2: 2025-02-14:1539_29*/
:root {
  --udu__less-version: "2025-02-14:1539_29";
}
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none !important;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
/* No Js */
.no-js .owl-carousel {
  display: block;
}
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}
/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			  This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			  calculation of the height of the owl-item that breaks page layouts
		   */
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.udu__prod-slider-itm-disp-flex .owl-item .item.udu__prod-gal-opacity-fixed-v2 {
  display: flex !important;
  opacity: 1 !important;
}
.udu__prod-slider-itm-disp-block .owl-item .item {
  display: block !important;
  opacity: 1 !important;
}
._ .udu__indexSliderControl:hover .owl-controls-auto,
._ .udu__indexSliderControl:hover .bx-controls-auto,
._ .udu__indexSliderControl:hover .nivo-controls-auto {
  transition: all 1s ease-out 0s;
  visibility: visible;
}
._ .udu__indexSliderControl:hover .owl-controls-auto a:not(.active),
._ .udu__indexSliderControl:hover .bx-controls-auto a:not(.active),
._ .udu__indexSliderControl:hover .nivo-controls-auto a:not(.active) {
  opacity: 1;
  transform: scale(1);
  z-index: 505;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 {
  bottom: 13px !important;
  display: inline-flex !important;
  height: auto;
  left: 50% !important;
  right: auto !important;
  top: auto !important;
  visibility: visible;
  width: auto;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a {
  border: 1px solid #fff !important;
  cursor: pointer;
  height: 25px !important;
  margin-left: 3px;
  opacity: 0.7 !important;
  position: relative !important;
  transform: scale(1);
  width: 25px !important;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.active {
  border: 1px solid #f00 !important;
  opacity: 1 !important;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-start::before {
  border-top: 7px solid transparent !important;
  border-bottom: 7px solid transparent !important;
  border-left: 11px solid rgba(255, 255, 255, 0.6) !important;
  right: 4px !important;
  top: 5px !important;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-stop::before,
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-stop::after {
  left: 6px;
  top: 5px;
  display: block;
  height: 13px;
  width: 5px;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-stop::after {
  left: auto;
  right: 5px;
  top: 5px;
}
._ .udu__indexSliderControl .owl-controls-auto,
._ .udu__indexSliderControl .bx-controls-auto,
._ .udu__indexSliderControl .nivo-controls-auto {
  height: 70px;
  position: absolute;
  right: calc(50% - 35px);
  top: calc(50% - 35px);
  width: 70px;
}
._ .udu__indexSliderControl .owl-controls-auto a,
._ .udu__indexSliderControl .bx-controls-auto a,
._ .udu__indexSliderControl .nivo-controls-auto a {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 0 !important;
  height: 70px;
  bottom: 0;
  opacity: 0;
  padding: 5px;
  position: absolute;
  right: 0;
  transform: scale(0.6);
  transition: all 0.3s ease-in-out 0s, opacity 1s ease-out 0s, scale 1s ease-out 0s;
  width: 70px;
  z-index: 0;
}
._ .udu__indexSliderControl .owl-controls-auto a:hover,
._ .udu__indexSliderControl .bx-controls-auto a:hover,
._ .udu__indexSliderControl .nivo-controls-auto a:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
._ .udu__indexSliderControl .owl-controls-auto a:hover.play::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.play::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.play::before,
._ .udu__indexSliderControl .owl-controls-auto a:hover.bx-start::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.bx-start::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.bx-start::before {
  border-left: 30px solid rgba(255, 255, 255, 0.8);
}
._ .udu__indexSliderControl .owl-controls-auto a:hover.stop::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.stop::before,
._ .udu__indexSliderControl .owl-controls-auto a:hover.bx-stop::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.bx-stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.bx-stop::before,
._ .udu__indexSliderControl .owl-controls-auto a:hover.stop::after,
._ .udu__indexSliderControl .bx-controls-auto a:hover.stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.stop::after,
._ .udu__indexSliderControl .owl-controls-auto a:hover.bx-stop::after,
._ .udu__indexSliderControl .bx-controls-auto a:hover.bx-stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.bx-stop::after {
  background-color: rgba(255, 255, 255, 0.8);
}
._ .udu__indexSliderControl .owl-controls-auto a.active,
._ .udu__indexSliderControl .bx-controls-auto a.active,
._ .udu__indexSliderControl .nivo-controls-auto a.active {
  opacity: 0;
  transform: scale(1);
  z-index: 49;
}
._ .udu__indexSliderControl .owl-controls-auto a.play::before,
._ .udu__indexSliderControl .bx-controls-auto a.play::before,
._ .udu__indexSliderControl .nivo-controls-auto a.play::before,
._ .udu__indexSliderControl .owl-controls-auto a.bx-start::before,
._ .udu__indexSliderControl .bx-controls-auto a.bx-start::before,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-start::before {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid rgba(255, 255, 255, 0.6);
  content: " ";
  display: block;
  height: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: all 0.3s ease-in-out 0s;
  width: 0;
}
._ .udu__indexSliderControl .owl-controls-auto a.stop::before,
._ .udu__indexSliderControl .bx-controls-auto a.stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a.stop::before,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop::before,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop::before,
._ .udu__indexSliderControl .owl-controls-auto a.stop::after,
._ .udu__indexSliderControl .bx-controls-auto a.stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a.stop::after,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop::after,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop::after {
  background-color: rgba(255, 255, 255, 0.6);
  content: " ";
  display: block;
  height: 30px;
  position: absolute;
  transition: all 0.3s ease-in-out 0s;
  width: 10px;
}
._ .udu__indexSliderControl .owl-controls-auto a.stop:before,
._ .udu__indexSliderControl .bx-controls-auto a.stop:before,
._ .udu__indexSliderControl .nivo-controls-auto a.stop:before,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop:before,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop:before,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop:before {
  left: 20px;
  top: 20px;
}
._ .udu__indexSliderControl .owl-controls-auto a.stop:after,
._ .udu__indexSliderControl .bx-controls-auto a.stop:after,
._ .udu__indexSliderControl .nivo-controls-auto a.stop:after,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop:after,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop:after,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop:after {
  right: 20px;
  top: 20px;
}
._ #slider.udu__mainBannerDotsPosition-LB .owl-dots {
  justify-content: flex-start;
}
._ #slider.udu__mainBannerDotsPosition-CB .owl-dots {
  justify-content: center;
}
._ #slider.udu__mainBannerDotsPosition-RB .owl-dots {
  justify-content: flex-end;
}
._ #slider.has-custom-height .owl-item img {
  object-fit: cover;
  object-position: center;
  width: 100%;
}
._ #slider > li {
  position: relative;
}
._ #slider .text-widget-placeholder {
  display: block;
}
._ .owl-carousel .owl-nav .owl-prev,
._ .owl-carousel .owl-nav .owl-next {
  -webkit-text-size-adjust: none;
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
._ #slider .owl-carousel .udu__owl-inner-link {
  display: block;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-1 .sub-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-delay: 0.7s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-1 .main-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-delay: 0.6s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-1 .desc {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-delay: 0.5s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-1 .slider-btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-delay: 0.4s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-2 .sub-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-delay: 0.7s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-2 .main-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-delay: 0.6s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-2 .desc {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-delay: 0.5s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-2 .slider-btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-delay: 0.4s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-3 .sub-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-delay: 0.7s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-3 .main-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-delay: 0.6s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-3 .desc {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-delay: 0.5s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-3 .slider-btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-delay: 0.4s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-4 .sub-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-delay: 0.7s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-4 .main-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-delay: 0.6s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-4 .desc {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-delay: 0.5s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-4 .slider-btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-delay: 0.4s;
}
._ #slider .owl-carousel .owl-caption {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  justify-content: center;
  left: 0;
  margin: 25px auto;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
._ #slider .owl-carousel .slider-content {
  width: 41.66666667%;
}
@media (min-width: 544px) and (max-width: 760px) {
  ._ #slider .owl-carousel .slider-content {
    width: 50%;
  }
}
._ #slider .owl-carousel .slider-content-left {
  align-self: flex-start;
  text-align: left;
}
._ #slider .owl-carousel .slider-content-center {
  align-self: center;
}
._ #slider .owl-carousel .slider-content-right {
  align-self: flex-end;
}
@media (min-width: 1200px) {
  ._ .main:not(.wide) #slider .owl-caption {
    max-width: 720px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  ._ .main:not(.wide) #slider .owl-caption {
    max-width: 576px;
  }
}
._ .main:not(.wide) #slider .slider-content .desc {
  display: none;
}
@media (min-width: 1200px) {
  ._ .main:not(.wide) #slider .slider-content .sub-title {
    font-size: 13px;
  }
  ._ .main:not(.wide) #slider .slider-content .main-title {
    font-size: 30px;
    line-height: 40px;
  }
  ._ .main:not(.wide) #slider .slider-content .slider-btn {
    font-size: 12px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  ._ .main:not(.wide) #slider .slider-content .sub-title {
    background: transparent !important;
    line-height: 20px;
    margin-bottom: 5px;
  }
  ._ .main:not(.wide) #slider .slider-content .main-title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 12px;
  }
  ._ .main:not(.wide) #slider .slider-content .slider-btn {
    font-size: 11px;
    padding: 7px 15px;
  }
}
@media (min-width: 1500px) {
  ._ .main.wide #slider .owl-caption {
    max-width: 1400px;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  ._ .main.wide #slider .owl-caption {
    max-width: 1170px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  ._ .main.wide #slider .owl-caption {
    max-width: 940px;
  }
}
@media (min-width: 1300px) and (max-width: 1499px) {
  ._ .main.wide #slider .slider-content .sub-title {
    font-size: 15px;
  }
  ._ .main.wide #slider .slider-content .main-title {
    font-size: 48px;
    line-height: 60px;
  }
  ._ .main.wide #slider .slider-content .desc {
    font-size: 15px;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  ._ .main.wide #slider .slider-content .main-title {
    font-size: 40px;
    line-height: 45px;
  }
  ._ .main.wide #slider .slider-content .desc {
    font-size: 12px;
    margin-bottom: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  ._ .main.wide #slider .slider-content .sub-title {
    font-size: 12px;
  }
  ._ .main.wide #slider .slider-content .main-title {
    font-size: 32px;
    line-height: 34px;
  }
  ._ .main.wide #slider .slider-content .desc {
    font-size: 11px;
  }
}
@media (max-width: 991px) {
  ._ #slider .owl-caption {
    max-width: 720px;
  }
}
@media (max-width: 760px) {
  ._ #slider .owl-caption {
    max-width: 576px;
  }
}
@media (max-width: 543px) {
  ._ #slider .owl-caption {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  ._ #slider .slider-content .sub-title {
    font-size: 12px;
    margin-bottom: 15px;
  }
  ._ #slider .slider-content .main-title {
    font-size: 24px;
    line-height: 15px;
    margin-bottom: 10px;
  }
  ._ #slider .slider-content .desc {
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 20px;
  }
}
@media (max-width: 760px) {
  ._ #slider .slider-content .sub-title {
    font-size: 24px;
    line-height: 15px;
  }
  ._ #slider .slider-content .main-title {
    font-size: 20px;
    letter-spacing: -1px;
  }
  ._ #slider .slider-content .slider-btn {
    font-size: 12px;
    padding: 8px 17px;
  }
}
@media (max-width: 543px) {
  ._ #slider .slider-content .sub-title {
    background: transparent !important;
    font-size: 20px;
    letter-spacing: -1px;
    margin-bottom: 5px;
  }
  ._ #slider .slider-content .main-title {
    margin-bottom: 12px;
  }
  ._ #slider .slider-content .slider-btn {
    font-size: 11px;
    padding: 7px 15px;
  }
}
@media (max-width: 480px) {
  ._ #slider .slider-content .sub-title {
    font-size: 12px;
    line-height: 16px;
  }
  ._ #slider .slider-content .main-title {
    margin-bottom: 10px;
  }
  ._ #slider .slider-content .slider-btn {
    font-size: 10px;
    padding: 5px 10px;
  }
}
@media (max-width: 415px) {
  ._ #slider .slider-content .sub-title {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 10px;
  }
  ._ #slider .slider-content .slider-btn {
    font-size: 9px;
    padding: 3px 8px;
  }
}
@media (max-width: 380px) {
  ._ #slider .slider-content .sub-title {
    font-size: 10px;
    margin-bottom: 5px;
  }
  ._ #slider .slider-content .main-title {
    font-size: 18px;
    line-height: 26px;
  }
}
@media (max-width: 350px) {
  ._ #slider .slider-content .sub-title {
    font-size: 9px;
  }
  ._ #slider .slider-content .main-title {
    font-size: 16px;
    line-height: 20px;
  }
}
#slider {
  border-radius: 10px;
  font-family: Rubik, sans-serif;
  height: 0;
  overflow: hidden;
  -webkit-transition: 0.8s ease-in 0.3s;
  -moz-transition: 0.8s ease-in 0.3s;
  -o-transition: 0.8s ease-in 0.3s;
  transition: 0.8s ease-in 0.3s;
}
#slider:hover .owl-carousel .owl-nav button.owl-prev,
#slider:hover .owl-carousel .owl-nav button.owl-next {
  opacity: 1;
}
#slider._done {
  height: auto;
}
#slider._done .owl-carousel {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: showSlider;
  animation-name: showSlider;
  animation-delay: 1s;
}
#slider .owl-carousel {
  height: auto;
  max-height: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
#slider .owl-carousel.owl-stage:after {
  content: none;
}
#slider .owl-dots {
  align-items: center;
  bottom: 14px;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: absolute;
  right: 0;
}
@media (max-width: 760px) {
  #slider .owl-dots {
    bottom: 7px;
  }
}
#slider .owl-dots .owl-dot {
  display: inline-block;
  margin: 0 2px;
  vertical-align: middle;
}
#slider .owl-dots .owl-dot:hover span,
#slider .owl-dots .owl-dot.active span {
  background-color: #232f3e;
  border-color: #232f3e;
}
#slider .owl-dots .owl-dot span {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 20px;
  display: block;
  height: 14px;
  margin: 0 3px;
  position: relative;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  width: 14px;
}
@media (max-width: 760px) {
  #slider .owl-nav {
    display: none;
  }
}
#slider .owl-nav button.owl-prev,
#slider .owl-nav button.owl-next {
  background-color: #232f3e;
  background-image: url(img/slider-arrow.png);
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 46px;
  margin: 4px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 25px);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  width: 46px;
}
#slider .owl-nav button.owl-prev:hover,
#slider .owl-nav button.owl-next:hover {
  background-color: #febd69;
}
#slider .owl-nav button.owl-prev {
  background-position: -6px -6px;
  left: 3%;
  right: auto;
}
#slider .owl-nav button.owl-next {
  background-position: -6px 50px;
  left: auto;
  right: 3%;
}
#slider .slider-content .sub-title {
  color: #bf4800;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-bottom: 20px;
}
#slider .slider-content .main-title {
  color: #1c1b1b;
  font-size: 52px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 80px;
  margin-bottom: 15px;
  -webkit-font-smoothing: antialiased;
}
#slider .slider-content .main-title span {
  display: block;
  font-weight: inherit;
}
#slider .slider-content .desc {
  color: #1c1b1b;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 1.4;
  margin-bottom: 36px;
}
#slider .slider-btn {
  background-color: #232f3e;
  border: none;
  border-radius: 25px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 18px;
  overflow: hidden;
  padding: 12px 30px;
  position: relative;
  z-index: 1;
}
#slider .slider-btn.btn-clear {
  background-color: transparent;
  color: #232f3e;
  border: 1px solid #232f3e;
}
._ .udu__index-cats-prods .owl-nav {
  display: flex;
  height: auto;
  position: absolute;
  right: 5px;
  top: -48px;
}
@media (max-width: 991px) {
  ._ .udu__index-cats-prods .owl-nav {
    top: -38px;
  }
}
._ .udu__index-cats-prods .owl-nav button.owl-prev,
._ .udu__index-cats-prods .owl-nav button.owl-next {
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
._ .udu__index-cats-prods .owl-nav button.owl-prev {
  background-image: url(img/icon-owl-prev.svg);
  height: 20px;
  width: 30px;
}
._ .udu__index-cats-prods .owl-nav button.owl-next {
  background-image: url(img/icon-owl-next.svg);
  height: 20px;
  width: 20px;
}
._ .udu__index-cats-prods .owl-nav button.disabled {
  opacity: 0.4;
}
._ .udu__index-cats-prods .owl-nav button span {
  font-size: 0;
}
#indexCarousel {
  background: #ffffff;
  box-shadow: 0 0 10px #0000001a;
  border-radius: 10px;
  margin-top: 70px;
}
@media (max-width: 760px) {
  #indexCarousel {
    padding: 0 15px;
    margin-top: 40px;
  }
}
@media (max-width: 543px) {
  #indexCarousel {
    margin-top: 30px;
  }
}
#indexCarousel .item {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 15px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
#indexCarousel .item:hover .nivo-add-html {
  height: 100%;
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
  z-index: 100;
}
#indexCarousel .item:hover img {
  opacity: 0.4;
  -webkit-transform: rotateY(360deg);
  -moz-transform: rotateY(360deg);
  -o-transform: rotateY(360deg);
  transform: rotateY(360deg);
}
#indexCarousel .item .nivo-add-html {
  align-items: center;
  background-color: rgba(68, 68, 68, 0.1);
  box-shadow: 0 1px 3px #00000020;
  color: #000000;
  display: flex;
  height: 0;
  justify-content: center;
  left: 0;
  max-height: 100%;
  opacity: 0;
  overflow: hidden;
  padding: 10px;
  position: absolute;
  text-align: center;
  text-shadow: 0 0 2px #000;
  top: 0;
  transform: scaleY(0);
  transform-origin: top;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 0;
}
#indexCarousel .item img {
  -webkit-transition: 0.5s ease-in-out 0s;
  -moz-transition: 0.5s ease-in-out 0s;
  -o-transition: 0.5s ease-in-out 0s;
  transition: 0.5s ease-in-out 0s;
}
#indexCarousel .owl-nav {
  display: none;
}
@media (max-width: 760px) {
  #indexCarousel .owl-nav {
    display: block;
  }
}
#indexCategories .owl-nav button.owl-prev,
#indexCarousel .owl-nav button.owl-prev,
#indexCategories .owl-nav button.owl-next,
#indexCarousel .owl-nav button.owl-next {
  color: #999999;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 36px;
  position: absolute;
  top: calc(50% - 18px);
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;
  width: 15px;
}
#indexCategories .owl-nav button.owl-prev:hover:not(.disabled),
#indexCarousel .owl-nav button.owl-prev:hover:not(.disabled),
#indexCategories .owl-nav button.owl-next:hover:not(.disabled),
#indexCarousel .owl-nav button.owl-next:hover:not(.disabled) {
  color: #000000;
}
#indexCategories .owl-nav button.owl-prev:before,
#indexCarousel .owl-nav button.owl-prev:before,
#indexCategories .owl-nav button.owl-next:before,
#indexCarousel .owl-nav button.owl-next:before {
  font-family: Linearicons;
  vertical-align: middle;
}
#indexCategories .owl-nav button.owl-prev.disabled,
#indexCarousel .owl-nav button.owl-prev.disabled,
#indexCategories .owl-nav button.owl-next.disabled,
#indexCarousel .owl-nav button.owl-next.disabled {
  color: #2323234d;
}
#indexCategories .owl-nav button.owl-prev,
#indexCarousel .owl-nav button.owl-prev {
  left: -16px;
  right: auto;
}
#indexCategories .owl-nav button.owl-prev:before,
#indexCarousel .owl-nav button.owl-prev:before {
  content: '\ec3b';
}
#indexCategories .owl-nav button.owl-next,
#indexCarousel .owl-nav button.owl-next {
  left: auto;
  right: -16px;
}
#indexCategories .owl-nav button.owl-next:before,
#indexCarousel .owl-nav button.owl-next:before {
  content: '\ec3c';
}
@keyframes showSlider {
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px;
  }
}
